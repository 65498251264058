import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout'

export const constantRoutes = [
    {
        path: '/',
        component: Layout,
        redirect: '/login',
        children: [
            {
                path: '/home',
                component: () => import('@/views/home'),
                hidden: true
            },
            {
                path: '/about-us',
                component: () => import('@/views/about-us'),
                hidden: true
            },
            {
                path: '/introduce',
                component: () => import('@/views/introduce'),
                hidden: true
            },
            {
                path: '/service',
                component: () => import('@/views/service'),
                hidden: true
            },
            {
                path: '/technology',
                component: () => import('@/views/technology'),
                hidden: true
            },
            {
                path: '/channel',
                component: () => import('@/views/channel'),
                hidden: true
            },
            {
                path: '/contact-us',
                component: () => import('@/views/contact-us'),
                hidden: true
            },
            {
                path: '/404',
                component: () => import('@/views/error-page/404'),
                hidden: true
            }
        ]
    },
    {
        path: '/login',
        component: () => import('@/views/login'),
        hidden: true
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes: constantRoutes
});

export default router
