import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import './assets/scss/common.scss'

createApp(App)
    .use(router)
    .use(ElementPlus)
    .mount('#app');
