<template>
    <div class="main-header" ref="main-header">

    </div>
</template>

<script>
    export default {
        name: 'main-header',

        data() {
            return {
                list: []
            }
        },

        methods: {
            jump(item) {
                this.list.forEach((item) => {
                    item.is_active = false;
                });
                item.is_active = true;
            }
        },

        async created() {
            await this.$nextTick();
            const { fullPath, hash } = this.$route;
            const target = this.list.find(item => item.href === fullPath);
            if(target) target.is_active = true;
            setTimeout(() => {
                if (hash) document.querySelector(hash).scrollIntoView();
            }, 100);
        }
    }
</script>

<style scoped lang="scss">
</style>
