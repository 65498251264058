<template>
    <div class="main-footer">
        <!-- 底部信息 -->
        <div class="footer">
            <div class="companyName">
                <p><a href="https://beian.miit.gov.cn/" style="color:#45b8dc;margin-right:15px">粤ICP备16072482号-7</a>广州百伦供应链科技有限公司</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'main-footer',
    }
</script>

<style scoped lang="scss">
    .companyName {
        text-align: center;
    }
</style>
